<template>
  <img :src="errorImage[errorCode]" alt="Error" />
</template>

<script>
import errorDefault from "@/assets/img/error-page.svg";
import error401 from "@/assets/img/error-page-401.svg";
import error404 from "@/assets/img/error-page-404.svg";
import error500 from "@/assets/img/error-page-500.svg";

export default {
  name: "ErrorImage",

  props: {
    errorCode: {
      type: Number,
      default: 100,
    },
  },

  computed: {
    errorImage() {
      return {
        100: errorDefault,
        401: error401,
        404: error404,
        500: error500,
      };
    },
  },
};
</script>
