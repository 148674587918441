<template>
  <div class="error-wrapper">
    <div :class="['error-content', classContent, classRight]">
      <div class="mb-16">
        <BaseLogoStampRed max-width="120" alt="Error" />
      </div>

      <BaseHeadline size="2" class="mb-4">{{ $t(title) }}</BaseHeadline>

      <BaseHeadline size="4" color="lowEmphasis" class="mb-12" v-html="$t(content)"></BaseHeadline>

      <div v-if="!noButton">
        <BaseButton @click="navigateTo">
          {{ $t(buttonLabel) }}
        </BaseButton>
      </div>
    </div>

    <div :class="['primary100 error-content', classContent, classLeft]">
      <ErrorImage :errorCode="errorCodeInt" />
    </div>
  </div>
</template>

<script>
import ErrorImage from "@components/ErrorPage/partials/ErrorImage.vue";

export default {
  name: "ErrorPageWrapper",

  components: {
    ErrorImage,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    content: {
      type: String,
      required: true,
    },

    errorCode: {
      type: String,
      default: "100",
    },

    routeName: {
      type: String,
      required: false,
    },

    buttonLabel: {
      type: String,
      required: false,
      default: "ERROR_PAGE.BUTTON_LABEL",
    },

    noButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    errorCodeInt() {
      return parseInt(this.errorCode, 10);
    },

    bp() {
      return this.$vuetify.breakpoint;
    },

    classLeft() {
      return this.bp.mdAndDown ? "error-content--left" : "error-content--left-desktop";
    },

    classRight() {
      return this.bp.mdAndDown ? "error-content--right" : "error-content--right-desktop";
    },

    classContent() {
      return this.bp.mdAndDown ? "" : "error-content-desktop";
    },
  },

  methods: {
    async navigateToRoute() {
      try {
        await this.$router.push({ name: this.routeName });
      } catch {
        return;
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    async navigateTo() {
      if (this.routeName) {
        this.navigateToRoute();
      } else {
        this.goBack();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  &-wrapper {
    height: 100%;
    position: relative;
  }

  &-content {
    padding: 40px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    &-desktop {
      padding: 0;
      width: 50%;
      height: 100%;
    }

    &--left {
      &-desktop {
        float: left;
      }
    }

    &--right {
      &-desktop {
        float: right;
      }
    }
  }
}
</style>
