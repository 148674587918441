<template>
  <ErrorPageWrapper title="ERROR_PAGE.404.TITLE" content="ERROR_PAGE.404.CONTENT" errorCode="404" />
</template>

<script>
import ErrorPageWrapper from "@components/ErrorPage/ErrorPageWrapper.vue";

export default {
  name: "PageNotFound",

  components: {
    ErrorPageWrapper,
  },
};
</script>
